import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import applyDevTools from "../../lib/applyDevTools";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { getSession } from "../../processes/sessionProcesses";
import lmsReducer from "../../reducers/lmsReducer";
import applyGlobalStyles from "../../components/library/applyGlobalStyles";
import AnottationBookWebView from "./AnnotationsBookWebView";

export function PrepareWebView() {
  const browserHistory = createBrowserHistory();
  const currentUserId = getSession()?.currentUserId;
  const initialState = { currentUserId };
  const store = createStore(
    lmsReducer(browserHistory),
    initialState,
    compose(applyMiddleware(routerMiddleware(browserHistory)), applyDevTools(window.__REDUX_DEVTOOLS_EXTENSION__))
  );

  applyGlobalStyles();

  return (
    <Provider store={store}>
      <AnottationBookWebView />
    </Provider>
  );
}
