import * as Dialog from "@radix-ui/react-dialog";
import React, { useEffect, useState } from "react";

import {
  AnnotationsBySpecialtyContainer,
  Divider,
  MockQuestionsContainer,
  MockTitle,
  PlaceholderContainer,
  PlaceholderDescription,
  QuestionsContainer,
  SpecialtyCard,
  SpecialtyTitleContainer,
  SubjectContainer,
  TitleWrapper,
  Wrapper
} from "./styles";

import Annotation from "../Annotation/index";
import { EMRButton } from "../../../componentsV2/Button";
import { FiExternalLink } from "react-icons/fi";
import { FilterButton } from "../../../componentsV2/FilterButton";
import Illustration from "../Illustration";
import Page from "../../library/Page";
import Pagination from "../../library/Pagination";
import { QuestionModal } from "../QuestionModal";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";
import Spinner from "../../library/Spinner";

export default function AnnotationsBookPage(props) {
  const { annotations, selectedFilter, setSelectedFilter, isLoadingAnnotations, fetchAnnotations } = props;

  const [annotationId, setAnnotationId] = useState(0);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const hasAnnotations = annotations.specialties.length > 0;
  const isPlaceholderVisible = !isLoadingAnnotations && !hasAnnotations;
  const isContentVisible = !isLoadingAnnotations && hasAnnotations;
  const isPaginationVisible = isContentVisible || isPlaceholderVisible;

  const MAXIMUM_NUMBER_OF_ANNOTATIONS = 10;

  const filterOptions = [
    {
      action: "ALL",
      title: "Todos"
    },
    {
      action: "SURGERY",
      title: "Cirurgia"
    },
    {
      action: "MEDIC_CLINIC",
      title: "Clínica médica"
    },
    {
      action: "GYNECOLOGY_OBSTETRICS",
      title: "Ginecologia e obstetrícia"
    },
    {
      action: "PEDIATRICS",
      title: "Pediatria"
    },
    {
      action: "PREVENTIVE_MEDICINE",
      title: "Medicina preventiva"
    }
  ];

  const selectFilter = {
    ALL: "",
    "": selectedFilter
  };

  function handleFetch(selectedBigAreaFilter, page) {
    const filter = selectFilter[selectedBigAreaFilter] ?? selectedBigAreaFilter;

    const filterSelect = filterOptions.find((filterOp) => {
      const filterKey = filter === "" ? "ALL" : filter;
      return filterOp.action === filterKey;
    });

    trackClickEvents({
      eventName: "Caderno de erros - interação de navegação",
      content: {
        NomeDoFiltro: filterSelect.title
      }
    });

    fetchAnnotations({
      bigAreaFilter: filter === "ALL" ? "" : filter,
      page: page
    });
  }

  function handleSelectQuestion(question) {
    const selectedFilterTitle = filterOptions.find((filter) => filter.action === selectedFilter)?.title;

    trackClickEvents({
      eventName: "Caderno de erros - interação de navegação",
      content: {
        BotaoVerQuestao: question.code,
        NomeDoFiltro: selectedFilterTitle
      }
    });
    setAnnotationId(question.annotations[0].id);
    setQuestionModalOpen(true);
  }

  function getQuestionNumber(questionCode) {
    const quentionNumber = questionCode.split("Q");
    const number = quentionNumber[quentionNumber.length - 1].match(/\d+/)[0];

    return number;
  }

  function cleanHTMLTags(text) {
    const doc = document.createElement("div");
    doc.innerHTML = text;
    const HTMLText = doc.children[0].innerHTML;

    if (doc.children[0].childNodes[0].tagName) {
      const parent = doc.children[0];
      const child = parent.children[0];

      return child.innerHTML.replace(/([()])/g, "");
    }

    return HTMLText.replace(/([()])/g, "");
  }

  function getQuestionTitle({ questionTitle, questionCode }) {
    const formattedQuestionTitle = cleanHTMLTags(questionTitle);

    return formattedQuestionTitle + " " + "Q" + getQuestionNumber(questionCode);
  }

  return (
    <Page title="Caderno de erros">
      <Wrapper>
        <FilterButton
          data={filterOptions}
          isLoading={isLoadingAnnotations}
          selectedValue={selectedFilter}
          setSelectedValue={setSelectedFilter}
          onSelectFilter={handleFetch}
          style={{ marginTop: 10 }}
          isAnnotationBookFilter={true}
        />

        {isLoadingAnnotations && <Spinner />}

        {isContentVisible && (
          <AnnotationsBySpecialtyContainer>
            {annotations.specialties.map((specialty, annotationsBySpecialtyIndex) => (
              <SpecialtyCard key={specialty.id} zIndex={MAXIMUM_NUMBER_OF_ANNOTATIONS - annotationsBySpecialtyIndex}>
                {specialty.subjects.map((subject, subjectIndex) => (
                  <SubjectContainer key={subject.name}>
                    <SpecialtyTitleContainer isFirstSpecialtySubject={subjectIndex === 0}>
                      <h3>{String(specialty.name).toLowerCase()}</h3>
                      <span>{String(subject.name).toLowerCase()}</span>
                    </SpecialtyTitleContainer>

                    <QuestionsContainer>
                      {subject.questions.map((question, questionIndex) => (
                        <MockQuestionsContainer key={questionIndex} isFirstQuestion={questionIndex === 0}>
                          <TitleWrapper>
                            <MockTitle>
                              {getQuestionTitle({
                                questionTitle: question.title,
                                questionCode: question.code
                              })}
                            </MockTitle>

                            <EMRButton mode={"transparent"} fontSize={"md"} onClick={() => handleSelectQuestion(question)}>
                              Ver questão
                              <FiExternalLink size={24} />
                            </EMRButton>
                          </TitleWrapper>

                          <Divider />

                          {question.annotations.map((annotation, annotationIndex) => (
                            <Annotation
                              filter={selectFilter[selectedFilter] ?? selectFilter}
                              page={annotations.current_page}
                              refetchAnnotations={fetchAnnotations}
                              key={annotationIndex}
                              annotationId={annotation.id}
                              annotationIndex={annotationIndex}
                              isLastAnnotation={question.annotations.length === annotationIndex + 1}
                              annotationUpdateDate={annotation.updated_at}
                              annotationDescription={annotation.annotation}
                              mockQuestionName={question.title}
                              questionNumber={getQuestionNumber(question.code)}
                            />
                          ))}
                        </MockQuestionsContainer>
                      ))}
                    </QuestionsContainer>
                  </SubjectContainer>
                ))}
              </SpecialtyCard>
            ))}
          </AnnotationsBySpecialtyContainer>
        )}

        {isPlaceholderVisible && (
          <PlaceholderContainer>
            <Illustration />
            <PlaceholderDescription>
              Com o seu Caderno de Erros, você consegue ver as anotações mais importantes que fizeram você errar as questões, para que esse
              erro não se repita e você alavanque seus estudos.
            </PlaceholderDescription>
          </PlaceholderContainer>
        )}

        {isPaginationVisible && (
          <Pagination
            fetchHandler={handleFetch}
            list={{
              totalPages: annotations.total_pages,
              currentPage: annotations.current_page
            }}
            loading={isLoadingAnnotations}
          />
        )}
      </Wrapper>

      {questionModalOpen && (
        <Dialog.Root open={questionModalOpen} onOpenChange={setQuestionModalOpen}>
          <QuestionModal annotationId={annotationId} onClose={() => setQuestionModalOpen(false)} />
        </Dialog.Root>
      )}
    </Page>
  );
}
