import React, { useEffect, useState } from "react";
import { PrepareWebView } from "./PrepareWebView";
import AnnotationsBookWebView from "./AnnotationsBookWebView";

export function WebViewClient() {
  const [isReady, setIsReady] = useState(false);

  function onRecievedMessage(event) {
    if (event.data?.includes && event.data?.includes("custom")) {
      const data = JSON.parse(event.data);
      if (data.cookies) {
        Object.keys(data.cookies).forEach((cookie) => {
          document.cookie = `${cookie}=${data.cookies[cookie]};`;
        });
      }
      if (data.localStorage) {
        localStorage.setItem("session", JSON.stringify(data.localStorage));
      }
      setIsReady(true);
    }
  }

  function clearCache() {
    const cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split(".");
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        const p = location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
    localStorage.removeItem("session");
  }

  useEffect(() => {
    window.addEventListener("message", onRecievedMessage);
    clearCache();
    parent.postMessage("ready", "*");
    if (typeof Webview !== "undefined" && Webview) Webview.postMessage("ready");

    const observer = new MutationObserver(() => {
      if (document.querySelector("#octadesk-octachat-appchat")) {
        document.querySelector("#octadesk-octachat-appchat").remove();
      }
    });

    observer.observe(document.querySelector("body"), { childList: true, subtree: true });

    return function () {
      window.removeEventListener("message", onRecievedMessage);
      observer.disconnect();
    };
  }, []);

  return isReady ? (
    <PrepareWebView>
      <AnnotationsBookWebView />
    </PrepareWebView>
  ) : (
    <></>
  );
}
