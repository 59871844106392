import React from "react";
import { IoClose, IoFlash, IoTimer, IoGolf, IoAlertCircle, IoLockClosed } from "react-icons/io5";
import { Container, Header, Content, AlertSection, Button, StyledCalendarSVG } from "./styles";
import { trackClickEvents } from "../../../../../../services/mixpanel/events/trackClickEvents";

export function RecalculateRouteFrial({
	guruUrl,
	completeGuruUrl,
}) {

	function handleRedirectToCheckout() {
		if (guruUrl) {
			trackClickEvents({
				eventName: "Clique em Recalcular rota",
				content: {
					UrlGuru: completeGuruUrl,
					Local: `Modal de recalcular rota frial`,
					Error: false,
				},
			});
			window.open(completeGuruUrl, "_self");
		}
	}

	return (
		<Container>
			<Header>
				<h1>Nunca perca o ritmo com o Recalcular</h1>
				<IoClose size={24} style={{ cursor: 'pointer' }} />
			</Header>
			<Content>
				<div>
					<p>
						Com o recalcular você <span>reorganiza suas tarefas automaticamente</span>.
						Isso garante que, mesmo com imprevistos, você mantenha o foco no que é mais importante.
					</p>
					<ul>
						<li>
							<IoFlash size={20} />
							<p>Reorganização automática do cronograma.</p>
						</li>
						<li>
							<IoTimer size={20} />
							<p>Repriorização do que é mais importante.</p>
						</li>
						<li>
							<IoGolf size={20} />
							<p>Mantenha o foco e a consistência até a prova.</p>
						</li>
					</ul>
				</div>
				<StyledCalendarSVG />
			</Content>
			<AlertSection>
				<IoAlertCircle size={24} />
				<p>Ao se matricular, você desbloqueia essa funcionalidade integralmente.</p>
			</AlertSection>
			<Button onClick={handleRedirectToCheckout}>
				<IoLockClosed size={24} />
				Recalcular rota
			</Button>
		</Container>
	);
}
