import { logout } from "./logout";

export default function UnauthorizedMiddleware(request, ignore) {
  const urlParams = new URLSearchParams(window.location.search);
  const isWebview = urlParams.get("webview") == "true";

  if (ignore || isWebview) return;

  request.on("error", function error(err) {
    if (err?.response?.unauthorized) {
      logout();
    }
  });
}
