import * as Dialog from "@radix-ui/react-dialog";
import { connect } from "react-redux";
import React from "react";
import { IoReloadOutline } from "react-icons/io5";
import { trackClickEvents } from "../../../../../services/mixpanel/events/trackClickEvents";
import { EMRButton as CancelButton } from "../../../Button";
import { useMedTrack } from "../../hooks/useMedTrack";
import { requestRecalculateRoute } from "../../utils/requestRecalculateRoute";
import {
	ButtonsContainer,
	ConfirmButton,
	DialogContent,
	DialogDescription,
	DialogOverlay,
	DialogTitle,
} from "./styles";
import { RecalculateRouteFrial } from "./RecalculateRouteFrial";

export function RecalculateRouteButton({ onRecalculateRoute, guruUrl, completeGuruUrl, ...rest }) {
	const { selectedMedtrackType } = useMedTrack();
	const [isOpen, setIsOpen] = React.useState(false);

	async function handleRecalculateRoute(e) {
		trackClickEvents({
			tagName: "Medtrack",
			eventName: "interação de navegação",
			content: {
				NomeDoBotao: "Recalcular Rota",
			},
		});
		await requestRecalculateRoute(selectedMedtrackType.id);
		await onRecalculateRoute(e);
	}

	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
			<Dialog.Trigger asChild>
				<ConfirmButton {...rest}>
					<IoReloadOutline size={24} />
					Recalcular
				</ConfirmButton>
			</Dialog.Trigger>

			{isFrial ? (
				<Dialog.Portal>
					<DialogOverlay onClick={() => setIsOpen(false)}>
						<RecalculateRouteFrial
							guruUrl={guruUrl}
							completeGuruUrl={completeGuruUrl}
						/>
					</DialogOverlay>
				</Dialog.Portal>
			) : (
				<Dialog.Portal>
					<DialogOverlay onClick={() => setIsOpen(false)}>
						<DialogContent>
							<DialogTitle>Deseja recalcular rota?</DialogTitle>
							<DialogDescription>
								Ao recalcular rota nossa inteligência artificial irá repriorizar
								todo seu cronograma a partir de agora, de acordo com suas metas e
								disponibilidade de tempo.
							</DialogDescription>
							<ButtonsContainer>
								<Dialog.Close asChild aria-label="Close">
									<CancelButton
										fontSize="lg"
										fullSized
										mediumWeight
										mode="cancel"
									>
										Cancelar
									</CancelButton>
								</Dialog.Close>
								<Dialog.Close asChild>
									<ConfirmButton onClick={handleRecalculateRoute} fullSized>
										<IoReloadOutline size={24} />
										Recalcular Rota
									</ConfirmButton>
								</Dialog.Close>
							</ButtonsContainer>
						</DialogContent>
					</DialogOverlay>
				</Dialog.Portal>
			)}
		</Dialog.Root>
	);
}

function mapStateToProps(state) {
	return {
		userSignInAttemptFails: state.userSignInAttemptFails,
		user: state.entities.users,
		isFrial: state.isFrialUser
	};
}

export default connect(mapStateToProps)(RecalculateRouteButton);
