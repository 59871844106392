import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Spinner } from "../../componentsV2/Spinner";
import AnnotationsBookContainer from "../../components/AnnotationsBook/AnnotationsBookContainer";
import { getSession } from "../../processes/sessionProcesses";

function AnnotationsBookWebView(props) {
  const { user } = props;

  useEffect(() => {
    if (user) {
      parent.postMessage("done", "*");
      if (typeof Webview !== "undefined" && Webview) Webview.postMessage("done");
    }
  }, [user]);

  return user ? <AnnotationsBookContainer /> : <Spinner />;
}

function mapStateToProps() {
  const user = getSession();

  return {
    user
  };
}

export default connect(mapStateToProps)(AnnotationsBookWebView);
