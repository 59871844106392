import React from "react";
import { connect } from "react-redux";
import LobbyRoutes from "./LobbyRoutes";
import LmsRoutes from "./LmsRoutes";
import { WebViewClient } from "./webview/WebViewClient";

function RoutesContainer({ history, loggedIn, ...rest }) {
  const urlParams = new URLSearchParams(window.location.search);
  const isWebview = urlParams.get("webview") === "true";

  if (isWebview) return <WebViewClient />;

  return loggedIn ? <LmsRoutes {...rest} /> : <LobbyRoutes {...rest} />;
}

function mapStateToProps({ currentUserId, isMedTrackFirstAccess }) {
  return {
    loggedIn: !!currentUserId,
    medtrackEnabled: isMedTrackFirstAccess.medtrackEnabled
  };
}

export default connect(mapStateToProps)(RoutesContainer);
